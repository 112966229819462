import React, { FunctionComponent } from 'react';
import Layout from '../components/Layout/Layout';
import { NavigationMenuContext } from '../contexts/NavigationMenuContext';
import { getNavbarCategories } from '../services/configurator/navbar/NavbarConfigurator';
import { CategoryModel } from '../services/content/category/models/CategoryModel';
import styles from '../styles/404.module.scss';
import { LeadingNewsSectionTemplate } from '../components/Section/SectionTemplates/LeadingNewsSectionTemplate';
import { getLeadingNewsSection } from '../services/configurator/homepage/HomepageConfigurator';
import { HomepageConfigModel } from '../services/configurator/homepage/models/HomepageConfigModel';
import Head from 'next/head';

type Props = {
    categories: CategoryModel[];
    leadingNewsConfigModel: HomepageConfigModel;
};

export async function getStaticProps() {
    const categories = await getNavbarCategories();
    const leadingNewsConfigModel = await getLeadingNewsSection();

    return {
        props: {
            categories: categories,
            leadingNewsConfigModel: leadingNewsConfigModel,
        },
        revalidate: 10,
    };
}

const Error404: FunctionComponent<Props> = ({ categories, leadingNewsConfigModel }) => {
    return (
        <NavigationMenuContext.Provider value={categories}>
            <Layout>
                <Head>
                    <link rel='canonical' href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}/videos`} />
                </Head>
                <section className='container'>
                    <section className={`${styles.container404}`}>
                        <span className={styles.spanSelector}>Ooops!</span>
                        <h1 className={styles.h1Selector}>404</h1>
                        <p className={styles.pSelector}>Страницата, която търсите в момента е недостъпна.</p>
                        <p className={styles.pSelector}>Моля, потърсете в търсачката за необходимото съдържание!</p>
                    </section>
                    {leadingNewsConfigModel &&
                        leadingNewsConfigModel.sections &&
                        leadingNewsConfigModel.sections[0] && (
                            <LeadingNewsSectionTemplate section={leadingNewsConfigModel.sections[0].content} />
                        )}
                </section>
            </Layout>
        </NavigationMenuContext.Provider>
    );
};

export default Error404;
